import React from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import SEO from "components/seo"
import LinkToPage from "components/common/LinkToPage"

import {
  ContentWrapper,
  LogoWrapper,
  RodoSection,
  CopyrightFooter,
  SectionHeader,
} from "assets/styles/rodoStyles"

import LogoHorizontal from "assets/images/logo-fullcolor.svg"
import ArrowLabel from "assets/images/feather-icons/arrow-black.svg"

const Rodo = ({ intl }) => (
  <>
    <SEO title="Privacy Policy" />
    <ContentWrapper>
      <LogoWrapper>
        <Link to="/">
          <img src={LogoHorizontal} alt="company logo horizontal" />
        </Link>
      </LogoWrapper>
      <article>
        <h2>
          <FormattedMessage id="rodo.title" />
        </h2>
        <RodoSection>
          <SectionHeader>
            <FormattedMessage id="rodo.section_1.header_1" />
          </SectionHeader>
          <p>
            <FormattedMessage id="rodo.section_1.paragraph_1a" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_1.paragraph_1b" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_1.paragraph_1c" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_1.paragraph_1d" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_1.paragraph_1e" />
          </p>
        </RodoSection>
        <RodoSection>
          <SectionHeader>
            <FormattedMessage id="rodo.section_2.header_2" />
          </SectionHeader>
          <p>
            <FormattedMessage id="rodo.section_2.paragraph_2a" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_2.paragraph_2b" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_2.paragraph_2c" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_2.paragraph_2d" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_2.paragraph_2e" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="rodo.section_2.list.item_1" />
            </li>
            <li>
              <FormattedMessage id="rodo.section_2.list.item_2" />
            </li>
          </ul>
          <p>
            <FormattedMessage id="rodo.section_2.paragraph_2f" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_2.paragraph_2g" />
          </p>
          <p>
            <FormattedMessage id="rodo.section_2.paragraph_2h" />
          </p>
        </RodoSection>
      </article>
      <LinkToPage>
        <Link to="/">
          <FormattedMessage id="go_back" />
          <img src={ArrowLabel} alt="arrow pointing right" />
        </Link>
      </LinkToPage>
      <CopyrightFooter>© Copyright Elpis 2020</CopyrightFooter>
    </ContentWrapper>
  </>
)

export default injectIntl(Rodo)
