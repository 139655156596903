import styled from "styled-components"
import { DEVICES } from "src/constants"

export const ContentWrapper = styled.div`
  height: 100%;
  padding: 40px;
  max-width: 900px;
  @media ${DEVICES.laptopL} {
    padding: 60px;
  }
  article {
    margin-top: 40px;
    h2 {
      margin-bottom: 50px;
    }
    img {
      margin-left: 5px;
    }
  }
  span {
    margin-top: 30px;
  }
`

export const LogoWrapper = styled.div`
  width: 155px;
  margin-bottom: 150px;
  img {
    height: auto;
    width: 100%;
  }
`

export const RodoSection = styled.div`
  margin-top: 40px;

  p {
    display: block;
    margin-top: 15px;
  }
  li {
    display: inline-block;
    margin-top: 10px;
  }
`

export const SectionHeader = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 40px;
`

export const CopyrightFooter = styled.span`
  color: var(--color-text_footer);
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-top: 70px;
`
